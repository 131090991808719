import { Routes, Route, useNavigate, Link } from "react-router-dom";
import {
  Button, Stack, Toolbar, Typography, AppBar, Container, Box, IconButton,
} from '@mui/material';
import { Contacts, Info, RequestQuote } from '@mui/icons-material';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Email from '@mui/icons-material/Email';
import Home from './Routes/Home';
import Contact from './Routes/Contact';
import About from './Routes/About'; 
import Error from './Routes/Error';
import Logo from './Assets/img/logo.png';

function App() {
  const navigate = useNavigate();

  function scroll() {
    navigate('/', { state: { scroll: true } });
  }

  return ( 
    <>
      <AppBar position="static" color="transparent">
        <Container maxWidth="lg">
          <Toolbar>
            <Button size="small" onClick={() => navigate('/', { state: null })}>
              <img src={Logo} style={{ height: "3.5em" }} alt="OstiaX" />
            </Button>
            <Typography noWrap sx={{ flex: 1 }} />
            <Stack spacing={2} direction="row" sx={{ display: { xs: 'none', md: 'block' } }}>
              <Button variant="text" color="secondary" component={Link} to="/about">ABOUT US</Button>
              <Button variant="text" color="secondary" component={Link} to="/contact">CONTACT</Button>
              <Button variant="contained" className="pinkRed" onClick={scroll}>Request Demo</Button>
            </Stack>
            <Stack spacing={2} direction="row" sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton component={Link} to="/about"><Info /></IconButton>
              <IconButton component={Link} to="/contact"><Contacts /></IconButton>
              <IconButton onClick={scroll}><RequestQuote color="error" /></IconButton>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/about" exact element={<About />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </main>
      <Box component="footer">
        <Container maxWidth="lg">
          <Toolbar>
            <Typography align="left" component="p" >
              <IconButton href="https://www.linkedin.com/company/ostiax/" target="_blank"><LinkedIn /></IconButton>
              <IconButton href="mailto:contact@ostiax.com" target="_blank"><Email /></IconButton>
            </Typography>     
            <Typography noWrap sx={{ flex: 1 }} />
            <Typography align="right" component="p" >
              Copyright © 2023 OstiaX Tech
            </Typography>            
          </Toolbar>
        </Container>
      </Box>
    </>
  );
}

export default App;
