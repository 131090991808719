import { useState } from "react";
import { Alert, Collapse, Container, IconButton, Grid, Typography, TextField, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { GoogleFormProvider, useGoogleForm, useShortAnswerInput, useLongAnswerInput } from "react-google-forms-hooks";
import { grey } from '@mui/material/colors';
import form from '../formContactUs.json';

// --- Dump Google Form to form.json ---
/* import { googleFormsToJson } from 'react-google-forms-hooks'
const result = await googleFormsToJson(
  'https://docs.google.com/forms/d/e/1FAIpQLSfrnuvjxCvhMGIEUHzJD9M6v0dt58InsICrof_VI-A5Dzu55A/viewform'
) // can use both full and shortened form url
console.log(JSON.stringify(result,2)) */
// ---

function ShortAnswerInput({ id }) {
    const { register, label } = useShortAnswerInput(id)

    return (
        <TextField label={label} {...register()} type="text" variant="outlined" fullWidth />
    )
}

function LongAnswerInput({ id }) {
    const { register, label } = useLongAnswerInput(id)

    return (
        <TextField label={label} {...register()} type="text" variant="outlined" multiline minRows={3} fullWidth />
    )
}

function Contact() {

    const [sent, setSent] = useState(false);
    const methods = useGoogleForm({ form });
    const onSubmit = async (data) => {
        await methods.submitToGoogleForms(data);
        setSent(true);
        methods.reset();
    }

    return (
        <Grid backgroundColor={grey[200]} container direction="column" paddingTop={5} paddingBottom={5}>
            <Container maxWidth="lg">
                <Grid container direction="row" paddingTop={10} paddingBottom={10} spacing={10}>
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column">
                            <Grid item xs={12}><Typography variant="h2" fontWeight={800}>Feel free to keep in touch</Typography></Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom paddingTop={3} paddingBottom={3}>
                                    We are here to help you with any inquiries you may have about our products and services. Please feel free to call or email us, or use our contact form to get in touch with us. We look forward to hearing from you!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>                 
                    <Grid item xs={12} md={6}>
                        <GoogleFormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Collapse in={sent}>
                                            <Alert action={
                                                <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setSent(false);}} >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>}
                                                sx={{ mb: 2 }}
                                            >
                                                Thank you for contacting us, we will get back to you as soon as possible.
                                            </Alert>
                                        </Collapse>
                                    </Grid>
                                    <Grid item xs={12}><ShortAnswerInput id='660937058' /></Grid>
                                    <Grid item xs={12}><ShortAnswerInput id='967914904' /></Grid>
                                    <Grid item xs={12}><ShortAnswerInput id='1878554166' /></Grid>
                                    <Grid item xs={12}><LongAnswerInput id='517010666' /></Grid>
                                    <Grid item xs={12}><Button type='submit' variant="contained" className="pinkRed">Send Message</Button></Grid>
                                </Grid>
                            </form>
                        </GoogleFormProvider>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default Contact;