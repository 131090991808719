import { Container, Grid, Typography, Button, IconButton } from "@mui/material";
import { grey } from '@mui/material/colors';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Email from '@mui/icons-material/Email';
import Andy from '../Assets/img/andy.png';
import Jason from '../Assets/img/jason.png';
import Johnny from '../Assets/img/johnny.png';
import Louis from '../Assets/img/louis.png';

const founders = [
    { title: 'Andy Zhang', subtitle: 'Co-founder & CEO', description: 'An accomplished data engineer, with a decade’s worth of experience from the National Institutes of Health and Meta Platforms. His expertise lies in big data system design, optimization, and efficiency. Master’s degree in Statistics from George Washington University.', icon: Andy },
    { title: 'Jason Zhu', subtitle: 'Advisor', description: 'As an honored tech pioneer holding dual master\'s degrees in Computer Science and Business, and acknowledged by Forbes, this accomplished author shines in the realm of data product development and in fostering cross-functional collaboration.', icon: Jason },
    { title: 'Johnny Li', subtitle: 'Co-founder & VP of Engineering', description: 'An experienced software engineer with a deep startup background. He specializes in data analytics and solutions for major retailers such as Target and Staples. With a keen interest in innovation and emerging technologies, he holds a Computer Science degree from George Washington University.', icon: Johnny },
    { title: 'Louis Li', subtitle: 'Co-founder & CMO', description: 'A seasoned entrepreneur and J.P. Morgan alum, with dual master’s degrees in finance and management. His decade-long expertise spans market expansion and project management across diverse fields, including international trade, consumer electronics, new material sciences and AI.', icon: Louis },
]

function About() {
    return (
        <>
            <Grid container className="banner" textAlign="center" direction="column" justifyContent="center" alignItems="center" paddingTop={12} paddingBottom={12}>
                <Typography variant="h2" color="white" fontWeight={800}>Founding Team</Typography>
            </Grid>
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="start" marginTop={10} marginBottom={10}>
                    <Typography variant="h6" gutterBottom paddingBottom={4}><u style={{ textUnderlineOffset: '0.5em' }}>Our Team</u></Typography>
                    <Typography variant="subtitle1" gutterBottom paddingBottom={2}>
                        At <b>OstiaX</b>, our team of experts brings extensive experience in data engineering and analysis, gained from leading data teams in both fast-growing startups and prominent tech companies. Drawing on our diverse backgrounds, we have identified a shared obstacle in the data domain: the ongoing challenge of developing and enhancing data systems. This is the precise issue we aim to tackle. Through our <b>OstiaX MindConnect Analytics</b> platform, we empower organizations to streamline the deployment of data applications, facilitating continuous improvement in real-world scenarios.
                    </Typography>
                    <Typography variant="subtitle1" paddingBottom={2} gutterBottom>Let’s talk.</Typography>
                    <Grid container direction="row">
                        <IconButton href="https://www.linkedin.com/company/ostiax/" target="_blank"><LinkedIn /></IconButton>
                        <IconButton href="mailto:contact@ostiax.com" target="_blank"><Email /></IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Container maxWidth="lg">
                <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={10} marginBottom={10}>
                    <Grid container direction="row" justifyContent="center" spacing={3}>
                        {founders.map(({ title, subtitle, description, icon }) => (
                            <Grid item textAlign="center" justifyContent="center" key={title} xs={12} sm={6} md={3}>
                                <img src={icon} width="100%" alt={title} />
                                <Typography variant="h6">{title}</Typography>
                                <Typography variant="subtitle1" gutterBottom>{subtitle}</Typography>
                                <Typography variant="body1" color="secondary" gutterBottom>{description}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Container>
            <Grid backgroundColor={grey[200]} container direction="column" textAlign="center" justifyContent="center" alignItems="center" paddingTop={10} paddingBottom={10}>
                <Container maxWidth="md">
                    <Typography variant="h4" fontWeight={800}>Join Our Team</Typography>
                    <Typography variant="subtitle1" gutterBottom paddingTop={3} paddingBottom={3}>
                        We are hiring for multiple engineering and design roles as part of our founding team!
                    </Typography>
                    <Button variant="contained" className="pinkRed">See open roles (Coming Soon)</Button>
                </Container>
            </Grid>
        </>
    );
}

export default About;