import { Grid, Typography} from "@mui/material";

function Error() {
    return (
        <Grid container className="banner" textAlign="center" direction="column" justifyContent="center" alignItems="center" paddingTop={12} paddingBottom={12}>
            <Typography variant="h2" color="white" fontWeight={800}>PAGE NOT FOUND</Typography>
        </Grid>
    );
}

export default Error;