import { useRef, useLayoutEffect, useState } from "react";
import { Alert, Button, Collapse, Container, IconButton, Grid, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from "react-router-dom";
import { GoogleFormProvider, useGoogleForm, useShortAnswerInput, useLongAnswerInput } from "react-google-forms-hooks";
import Flow from '../Assets/img/flow.png';
import { ReactComponent as Click } from '../Assets/img/click.svg';
import { ReactComponent as Data } from '../Assets/img/data.svg';
import { ReactComponent as Machine } from '../Assets/img/machine.svg';
import { ReactComponent as Report } from '../Assets/img/report.svg';
import { grey } from '@mui/material/colors';
import form from '../formRequestDemo.json';

// --- Dump Google Form to form.json ---
/* import { googleFormsToJson } from 'react-google-forms-hooks'
const result = await googleFormsToJson(
  'https://docs.google.com/forms/d/e/1FAIpQLSdOWflfLdtwYcUNVGqQ3lpbcddwvgxXEam39XS793T1EbAQLA/viewform'
) // can use both full and shortened form url
console.log(JSON.stringify(result,2)) */
// ---

const summary = [
    { title: 'Codeless + Just Click', description: 'Users simply input their ideas and with a few clicks, analysis can be performed without the need for coding', icon: Click },
    { title: 'GPT + LLM', description: 'Incorporates advanced generative pre-training models and generative AI technologies, enabling automatic recognition and understanding of user-inputted data and inquiries', icon: Machine },
    { title: 'Cross-Platform Data Transform', description: 'Easily retrieve and transform data from any system, including files, APIs, databases, data lakes, and data warehouses etc', icon: Data },
    { title: 'Visual Report and Analysis', description: 'Offer diverse visualization tools and templates for customized reports and visual charts, enabling users to gain insights and comprehend data effectively', icon: Report },
]

function ShortAnswerInput({ id }) {
    const { register, label } = useShortAnswerInput(id)

    return (
        <TextField label={label} {...register()} type="text" variant="outlined" fullWidth />
    )
}

function LongAnswerInput({ id }) {
    const { register, label } = useLongAnswerInput(id)

    return (
        <TextField label={label} {...register()} type="text" variant="outlined" multiline minRows={3} fullWidth />
    )
}

function Home() {
    const ref = useRef();
    const { state } = useLocation();
    function scroll() {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    useLayoutEffect(() => {
        if (state?.scroll) scroll();
    }, [state])

    const [sent, setSent] = useState(false);
    const methods = useGoogleForm({ form });
    const onSubmit = async (data) => {
        await methods.submitToGoogleForms(data);
        setSent(true);
        methods.reset();
    }
    
    return (
        <>
            <Grid container className="banner" textAlign="center" direction="column" justifyContent="center" alignItems="center" paddingTop={12} paddingBottom={12}>
                <Typography variant="h2" color="white" fontWeight={800}>AI-Powered Data Insights, Code-Free.</Typography>
                <Typography variant="subtitle1" color="white" paddingTop={4} paddingBottom={4} gutterBottom>
                    OstiaX – MindConnect speeds up data retrieval, transformation,<br />
                    and analysis through the use of generative AI technology,<br />
                    enabling effortless, code-free data analysis and insights.
                </Typography>
                <Button variant="contained" className="white" onClick={scroll}>Request Demo</Button>
            </Grid>
            <Container maxWidth="lg">
                <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={12} marginBottom={12}>
                    <Grid container direction="row" justifyContent="center" spacing={3}>
                        {summary.map(({ title, description, icon: Icon }) => (
                            <Grid item key={title} xs={12} sm={6} md={3}>
                                <Grid container textAlign="center" justifyContent="top" alignItems="center" direction="column">
                                    <Icon height="2em" />
                                    <Typography variant="h7" fontWeight={800} paddingBottom={3} paddingTop={3}>{title}</Typography>
                                    <Typography variant="body1" gutterBottom>{description}</Typography>
                                </Grid >
                            </Grid>
                        )) }
                    </Grid>
                    <Grid container paddingTop={5} justifyContent="center" alignItems="center"><img src={Flow} width="80%" alt="work flow" /></Grid>
                </Grid>
            </Container>
            <Grid ref={ref} backgroundColor={grey[200]} container direction="column" textAlign="center" justifyContent="center" alignItems="center" paddingTop={10} paddingBottom={10}>
                <Container maxWidth="md">
                    <Typography variant="h4" fontWeight={800} paddingBottom={2}>Excited to witness a demo?</Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Our platform is currently in private beta.<br /> Are a brief description of your use case, and we’ll promptly reach out to you!
                    </Typography>
                    <Collapse in={sent}>
                        <Alert action={
                            <IconButton aria-label="close" color="inherit" size="small" onClick={() => {setSent(false);}} >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>}
                            sx={{ mb: 2 }}
                        >
                            Thank you for contacting us, we will get back to you as soon as possible.
                        </Alert>
                    </Collapse>
                    <GoogleFormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Grid container direction="column" spacing={3} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12}><ShortAnswerInput id='1803731172' /></Grid>
                                <Grid item xs={12}><ShortAnswerInput id='1270334889' /></Grid>
                                <Grid item xs={12}><LongAnswerInput id='1544375179' /></Grid>
                            </Grid>
                            <Button type='submit' variant="contained" className="pinkRed">Request Demo</Button>
                        </form>
                    </GoogleFormProvider>
                </Container>
            </Grid>
        </>
    );
}

export default Home;
